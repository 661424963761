import React from 'react';
import './App.scss';

function App() {
  return (
    <div className="App">
      <h1 className="display-1">
        Aletheia Group
        <p className="lead">
          Providing service indistinguishable from magic.
        </p>
      </h1>
    </div>
  );
}

export default App;
